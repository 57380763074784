import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'Yaminote advises, builds and invests in companies with contrarian worldviews.',
  title: 'Yaminote',
  url: 'https://yaminote.com',
  author: 'Yaminote Private Limited',
  keywords: ['yaminote', 'yaminote private limited', 'yaminote pte ltd', 'yaminote singapore', 'yaminote investments', 'yaminote seed', 'yaminote design', 'yaminote development', 'seed investments', 'super angel investment', 'design and build mvp', 'it consultancy', 'software consultancy', 'built great products', 'ingenious design', 'how to build a digital product', 'how to design a digital product', 'how to build a sass product', 'how to design a sass product', 'need help with my sass product', 'design for your digital service'],
  twitter: {
    img: 'https://raw.githubusercontent.com/jackveiga/yaminote-website/v2/src/images/twitter%20og_image.png?token=AABUVLW4QHP4COALUGPDJSK5WMYPM',
  },
  facebook: {
    img: 'https://raw.githubusercontent.com/jackveiga/yaminote-website/v2/src/images/og_image.png?token=AABUVLT2KD5FIUMLKESPXLS5WMYPC',
  },
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.facebook.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>Yaminote</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
